import(/* webpackMode: "eager", webpackExports: ["LogoutButton","LoginButton","RegisterButton"] */ "/app/client/components/auth-buttons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteFooter"] */ "/app/client/components/global-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/client/components/skip-to-main.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar"] */ "/app/client/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Dialog","DialogTrigger","DialogContent","DialogClose"] */ "/app/client/components/ui/dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent"] */ "/app/client/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/app/client/components/ui/separator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/client/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/client/node_modules/next/dist/client/image-component.js");
